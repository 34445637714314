import { action } from '@ember/object';
import { service } from '@ember/service';
import { attr, belongsTo } from '@ember-data/model';
import type AbilitiesService from 'ember-can/services/abilities';
import { modelAction } from 'ember-custom-actions';
import { callAction } from 'ember-smily-base/utils/model';
import type BookingModel from 'smily-admin-ui/models/booking';
import { BaseModel } from 'smily-admin-ui/utils/model';
import { refreshRoute } from 'smily-admin-ui/utils/routing';

export type PaymentKind =
  | 'online'
  | 'cash'
  | 'wiretransfer'
  | 'cheque'
  | 'paypal'
  | 'credits'
  | 'travel-cheque'
  | 'credit-card'
  | 'bookingsync'
  | 'bookingsync-test'
  | 'instant-booking';

export const EDITABLE_PAYMENT_KINDS: PaymentKind[] = [
  'online',
  'cash',
  'wiretransfer',
  'cheque',
  'paypal',
  'credits',
  'travel-cheque',
  'credit-card',
];
export const PAYMENT_KINDS: PaymentKind[] = [
  ...EDITABLE_PAYMENT_KINDS,
  'bookingsync',
  'bookingsync-test',
  'instant-booking',
];

export default class PaymentModel extends BaseModel {
  @service abilities!: AbilitiesService;

  @attr kind?: PaymentKind;
  @attr('number') amount!: number;
  @attr('number') refundAmount?: number;
  @attr('string', { readOnly: true }) currency?: string;
  @attr('date') paidAt?: Date;
  @attr notes?: string;
  @attr('date', { readOnly: true }) authorizedAt?: Date;
  @attr('date', { readOnly: true }) canceledAt?: Date;
  @attr('date', { readOnly: true }) rejectedAt?: Date;
  @attr('date', { readOnly: true }) capturableFrom?: Date;

  @belongsTo('booking', { async: false, inverse: 'payments' })
  booking!: BookingModel;

  get isPaid(): boolean {
    return !!this.paidAt;
  }

  get isAuthorized(): boolean {
    return !!this.authorizedAt && !this.paidAt;
  }

  get isCanceled() {
    return !!this.canceledAt;
  }

  get status() {
    if (this.isCanceled) {
      return 'canceled';
    } else if (this.isPaid) {
      return 'paid';
    } else if (this.isAuthorized) {
      return 'authorized';
    }

    return '';
  }

  get date() {
    return this.isAuthorized ? this.authorizedAt : this.paidAt;
  }

  get isValidPayment(): boolean {
    return (
      !this.canceledAt && !this.rejectedAt && (this.isPaid || this.isAuthorized)
    );
  }

  get isEditable() {
    return (
      !!this.kind &&
      EDITABLE_PAYMENT_KINDS.includes(this.kind) &&
      this.abilities.can('edit payments')
    );
  }

  get paymentSortDate() {
    return this.paidAt ?? this.authorizedAt;
  }

  // not a task because <LoadingButton> unrendering would cancel it
  // TODO switch to an unlinked task once <LoadingButton> can take a task instead
  // of a task instance as an action {{perform this.deletePayment}}
  @action
  async deleteForBooking() {
    await this.destroyRecord();
    refreshRoute(this, 'bookings.booking');
  }

  // TODO same as above
  @action
  async restoreForBooking() {
    await callAction(this, 'restore', {
      data: {
        id: this.id,
        type: 'payment',
      },
    });
    refreshRoute(this, 'bookings.booking');
  }

  restore = modelAction<PaymentModel>('restore', {
    method: 'PATCH',
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    payment: PaymentModel;
  }
}
