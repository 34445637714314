import { fn } from '@ember/helper';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BsCollapse from 'ember-bootstrap/components/bs-collapse';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import media from 'ember-responsive/helpers/media';
import Icon from 'ember-smily-base/components/icon';
import IconText from 'ember-smily-base/components/icon-text';
import Link from 'ember-smily-base/components/link';
import List from 'ember-smily-base/components/list';
import ModalDialog from 'ember-smily-base/components/modal-dialog';
import Table from 'ember-smily-base/components/table';
import formatMoney from 'ember-smily-base/helpers/format-money';
import timestamp from 'ember-smily-base/helpers/timestamp';
import not from 'ember-truth-helpers/helpers/not';
import BookingsPaymentActions from 'smily-admin-ui/components/bookings/payment-actions';
import optionLabel from 'smily-admin-ui/helpers/option-label';
import type BookingModel from 'smily-admin-ui/models/booking';

function getStatusClass(status: string) {
  switch (status) {
    case 'paid':
      return 'text-success';
    case 'canceled':
      return 'text-danger';
    default:
      return 'text-warning';
  }
}

interface BookingsPaymentHistorySignature {
  Element: HTMLDivElement;
  Args: {
    booking: BookingModel;
  };
}

export default class BookingsPaymentHistory extends Component<BookingsPaymentHistorySignature> {
  @service intl!: IntlService;

  @tracked isPaymentHistoryExpanded = false;

  tableConfig = [
    { label: this.intl.t('internals.fields.status') },
    { label: this.intl.t('common.date') },
    { label: this.intl.t('internals.fields.kind') },
    { label: this.intl.t('internals.fields.amount') },
    { label: this.intl.t('common.actions') },
  ];

  get payments() {
    return [...this.args.booking.sortedPayments].reverse();
  }

  <template>
    <Link
      @action={{fn
        (mut this.isPaymentHistoryExpanded)
        (not this.isPaymentHistoryExpanded)
      }}
      class='btn btn-link ps-0 py-0 smily-select-like with-smily-select-icon'
      aria-expanded={{if this.isPaymentHistoryExpanded 'true'}}
    >
      <div>
        {{t 'finance.payment_history'}}
      </div>

      <div class='smily-select-icon'>
        <Icon @icon='chevron-down' />
      </div>
    </Link>

    <BsCollapse @collapsed={{not this.isPaymentHistoryExpanded}}>
      <div class='mt-3'>
        {{#if (media 'isSmallScreen')}}
          <List @linked={{true}} @sticky={{true}} as |Item|>
            {{#each this.payments as |payment|}}
              <Item>
                <ModalDialog @type='side' as |dialog|>
                  <dialog.toggle
                    @useLink={{true}}
                    class='w-100 d-flex justify-content-between align-items-center gap-3'
                  >
                    <div class='d-flex flex-column align-items-start'>
                      <div class='fw-semibold'>
                        {{formatMoney payment.amount @booking.currency}}
                      </div>

                      <div>
                        {{if payment.date (timestamp payment.date) '?'}}
                      </div>
                    </div>

                    <div class='d-flex gap-2'>
                      <IconText
                        @icon='circle'
                        @text={{optionLabel
                          payment.status
                          'finance.payment_status_options'
                        }}
                        @iconClass='text-8 {{getStatusClass payment.status}}'
                        @gap={{1}}
                      />

                      <div>
                        <Icon @icon='chevron-right' />
                      </div>
                    </div>
                  </dialog.toggle>

                  <dialog.modal as |modal|>
                    <modal.header>
                      <h4>
                        {{t 'finance.payment_details'}}
                      </h4>
                    </modal.header>

                    <modal.body>
                      <List @sticky={{true}} @justified={{true}} as |Item|>
                        <Item>
                          <div class='fw-semibold'>
                            {{t 'internals.fields.amount'}}
                          </div>

                          <div>
                            {{formatMoney payment.amount @booking.currency}}
                          </div>
                        </Item>

                        <Item>
                          <div class='fw-semibold'>
                            {{t 'internals.fields.status'}}
                          </div>

                          <div>
                            <IconText
                              @icon='circle'
                              @text={{optionLabel
                                payment.status
                                'finance.payment_status_options'
                              }}
                              @iconClass='text-8 {{getStatusClass
                                payment.status
                              }}'
                              @gap={{1}}
                            />
                          </div>
                        </Item>

                        <Item>
                          <div class='fw-semibold'>
                            {{t 'internals.fields.kind'}}
                          </div>

                          <div>
                            {{optionLabel
                              payment.kind
                              'finance.payment_kind_options'
                            }}
                          </div>
                        </Item>

                        <Item>
                          <div class='fw-semibold'>
                            {{t 'common.date'}}
                          </div>

                          <div>
                            {{if payment.date (timestamp payment.date) '?'}}
                          </div>
                        </Item>
                      </List>
                    </modal.body>

                    {{#if payment.isEditable}}
                      <modal.footer class='d-flex gap-2'>
                        <BookingsPaymentActions
                          @booking={{@booking}}
                          @payment={{payment}}
                          as |Actions|
                        >
                          {{#if payment.isCanceled}}
                            <Actions.Restore class='btn btn-primary btn-sm' />
                          {{else}}
                            <Actions.Cancel class='btn btn-danger btn-sm' />
                          {{/if}}

                          <Actions.Edit class='btn btn-secondary btn-sm' />
                        </BookingsPaymentActions>
                      </modal.footer>
                    {{/if}}
                  </dialog.modal>
                </ModalDialog>
              </Item>
            {{/each}}
          </List>
        {{else}}
          <Table
            @config={{this.tableConfig}}
            @items={{this.payments}}
            as |payment|
          >
            <td>
              <IconText
                @icon='circle'
                @text={{optionLabel
                  payment.status
                  'finance.payment_status_options'
                }}
                @iconClass='text-8 {{getStatusClass payment.status}}'
                @gap={{1}}
              />
            </td>

            <td>{{if payment.date (timestamp payment.date) '?'}}</td>

            <td>
              {{optionLabel payment.kind 'finance.payment_kind_options'}}
            </td>

            <td>{{formatMoney payment.amount @booking.currency}}</td>

            {{#if payment.isEditable}}
              <td class='d-flex'>
                <BookingsPaymentActions
                  @booking={{@booking}}
                  @payment={{payment}}
                  as |Actions|
                >
                  {{#if payment.isCanceled}}
                    <Actions.Restore class='btn btn-link btn-sm p-0' />
                  {{else}}
                    <Actions.Cancel class='btn btn-link btn-sm p-0' />
                  {{/if}}

                  |

                  <Actions.Edit class='btn btn-link btn-sm p-0' />
                </BookingsPaymentActions>
              </td>
            {{/if}}
          </Table>
        {{/if}}
      </div>
    </BsCollapse>
  </template>
}
