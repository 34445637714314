import type { TOC } from '@ember/component/template-only';
import SmilyLayoutSidebarSubmenuBody from 'ember-smily-base/components/smily-layout/sidebar/submenu/body';
import SmilyLayoutSidebarSubmenuHeader from 'ember-smily-base/components/smily-layout/sidebar/submenu/header';

const routes = [
  'x-settings.billing',
  'x-settings.general',
  'x-settings.users',
  'x-settings.accounts-users',
  'x-settings.payments',
  'x-settings.contacts',
  'x-settings.fees',
  'x-settings.taxes',
  'x-settings.rental-links',
  'x-settings.rentals-tags',
  'x-settings.bookings-tags',
  'x-settings.sources',
  'x-settings.bookings',
  'x-settings.notifications',
  'x-settings.public-calendars',
  'x-settings.inquiries',
  'x-settings.exports',
];

const SidebarSubmenusSettings: TOC<{
  Args: {
    isTemporarySubmenu?: boolean;
  };
}> = <template>
  <SmilyLayoutSidebarSubmenuHeader
    @route='settings'
    @isTemporarySubmenu={{@isTemporarySubmenu}}
  />

  <SmilyLayoutSidebarSubmenuBody @routes={{routes}} />
</template>;

export default SidebarSubmenusSettings;
