import EmberRouter from '@ember/routing/router';
import config from 'smily-admin-ui/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('auth', function () {
    this.route('login');
    this.route('logout');
  });

  this.route('signup', function () {
    this.route('free');
    this.route('business');
    this.route('manager');
    this.route('owner');
    this.route('cobranded-signup', { path: ':type' });
  });

  this.route('signup-business');
  this.route('signup-cdiscount');
  this.route('signup-free');
  this.route('signup-maeva');
  this.route('signup-manager');
  this.route('signup-owner');
  this.route('signup-skiset');

  this.route('terms-of-service');
  this.route('privacy-policy');

  this.route('confirm-email');
  this.route('complete-channel-signup');
  this.route('channel-import-in-progress');
  this.route('channel-import-complete');

  this.route('upgrade-to-smily');

  this.route('dashboard');

  this.route('tasks', function () {
    this.route('new');
    this.route('new-from-template', {
      path: 'new-from-template/:task_management_template_id',
    });
    this.route('task', { path: ':task_management_task_id' }, function () {
      this.route('manage');
    });

    this.route('templates', function () {
      this.route('new');
      this.route('duplicate', {
        path: 'duplicate/:task_management_template_id',
      });
      this.route('template', { path: ':task_management_template_id' });
    });
  });

  this.route('inbox', function () {
    this.route('conversation', { path: ':inbox_conversation_id' });
    this.route('new');

    // TODO to be removed at some point
    this.route('old-conversation', {
      path: 'conversations/:inbox_conversation_id',
    });
  });

  this.route('calendar', function () {
    this.route('legacy');
    this.route('planning');
    this.route('month');
  });

  this.route('bookings', function () {
    this.route('booking', { path: ':booking_id' }, function () {
      this.route('edit');
      this.route('contract');
    });
    this.route('new');
  });
  this.route('old-bookings');
  this.route('old-bookings-with-calendar-name');

  this.route('clients', { path: 'guests' }, function () {
    this.route('new');
    this.route('client', { path: ':client_id' }, function () {
      this.route('edit');
    });
  });

  this.route('finance', function () {
    this.route('completed-payouts');
    this.route('upcoming-payouts');
    this.route('gross-earnings');

    this.route('discover');
  });

  this.route('discounts', function () {
    this.route('new');
    this.route('discount', { path: ':discount_code_id' });
  });

  this.route('rentals', function () {
    this.route('new');
    this.route('rental', { path: ':rental_id' }, function () {
      this.route('details', function () {
        this.route('photos');
        this.route('description');
        this.route('rooms');
        this.route('amenities');
        this.route('location');
        this.route('languagues');
      });
      this.route('booking-settings', function () {
        this.route('checkin-checkout');
        this.route('preferences');
      });
      this.route('rates');
      this.route('legal', function () {
        this.route('edit');
      });

      this.route('edit');
      this.route('rental-agreement');

      this.route('taxes');
    });
  });

  this.route('performance', function () {
    this.route('calendar-occupancy');
    this.route('financial-metrics');
    this.route('quality-metrics');
  });

  this.route('inquiries', function () {
    this.route('inquiry', { path: ':inquiry_id' });
  });

  this.route('reviews', function () {
    this.route('guest', function () {
      this.route('review', { path: ':review_id' });
    });
    this.route('host', function () {
      this.route('review', { path: ':host_review_id' });
      this.route('automation', function () {
        this.route('new');
        this.route('comment', { path: ':reviews_host_automation_comment_id' });
      });
    });
  });

  this.route('apps', function () {
    this.route('app', { path: ':app_id' });
    // this.mount('bsa-website-ui-engine', { as: 'website', path: '/website' });
    this.mount('bsa-invoices-ui-engine', { as: 'invoices', path: '/invoices' });
  });

  this.route('profile');

  this.route('settings', function () {
    this.route('owner', function () {
      this.route('edit');
    });
    this.route('company', function () {
      this.route('edit');
      this.route('legal');
    });
    this.route('billing', function () {
      this.route('edit');
      this.route('credit-card');
      this.route('payments');
    });
    this.route('access', function () {
      this.route('edit');
    });
    this.route('privacy', function () {
      this.route('edit');
    });
  });

  this.route(
    'credit-card-auth',
    { path: '/credit-card-auth/:credit_card_id' },
    function () {
      this.route('pending');
      this.route('3ds');
      this.route('done');
    },
  );

  this.route('onboarding', function () {
    this.route('contact');
    this.route('company', function () {
      this.route('type');
      this.route('details');
      this.route('legal');
    });
    this.route('bank');
    this.route('services');
    this.route('book-a-call');
    this.route('phone', function () {
      this.route('verify');
      this.route('confirm');
    });
    this.route('payment-account-verification');

    this.route('rental');

    this.route('billing');
    this.route('company-documents');
    this.route('identity');
    this.route('tax-information');
    this.route('picture');

    this.route('profile-completed');
  });

  this.route('account', function () {
    this.route('setup', function () {
      this.route('upgrade', { path: '/confirmation' });
      this.route('onboarding', { path: '/*wildcard' });
    });
  });

  this.route('x-settings', function () {
    this.route('billing');
    this.route('invoices');
    this.route('general');
    this.route('users');
    this.route('accounts-users');
    this.route('payments');
    this.route('contacts');
    this.route('fees');
    this.route('taxes');
    this.route('rental-links');
    this.route('rentals-tags');
    this.route('bookings-tags');
    this.route('sources');
    this.route('bookings');
    this.route('notifications');
    this.route('public-calendars');
    this.route('inquiries');
    this.route('exports');
  });

  this.route('account-suspended');

  this.route('404', { path: '/*' });
});
