import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import can from 'ember-can/helpers/can';
import type AbilitiesService from 'ember-can/services/abilities';
import type IntlService from 'ember-intl/services/intl';
import { pageTitle } from 'ember-page-title';
import type MediaService from 'ember-responsive';
import RouteTemplate from 'ember-route-template';
import type { MetricsAdapterConfig } from 'ember-smily-base/components/analytics';
import Analytics from 'ember-smily-base/components/analytics';
import SmilyLayout from 'ember-smily-base/components/smily-layout';
import type StoreService from 'ember-smily-base/services/store';
import { isMobile } from 'ember-smily-base/utils/application';
import type {
  MenuConfigEntry,
  MobileMenuConfig,
  RouteIcons,
} from 'ember-smily-base/utils/routing';
import and from 'ember-truth-helpers/helpers/and';
import AcceptanceModals from 'smily-admin-ui/components/acceptance-modals';
import AccountSuspensionBanner from 'smily-admin-ui/components/account-suspension-banner';
import FeatureDiscovery from 'smily-admin-ui/components/feature-discovery';
import SidebarFooterComponent from 'smily-admin-ui/components/sidebar/footer';
import SidebarHeaderComponent from 'smily-admin-ui/components/sidebar/header';
import SidebarLockedMenuItemComponent from 'smily-admin-ui/components/sidebar/locked-menu-item';
import SidebarMobileMenuFooter from 'smily-admin-ui/components/sidebar/mobile-menu/footer';
import config from 'smily-admin-ui/config/environment';
import type ApplicationController from 'smily-admin-ui/controllers/application';
import type CacheService from 'smily-admin-ui/services/cache';
import type SessionService from 'smily-admin-ui/services/session-service';
import {
  featureDiscoveryConfig,
  menuConfig,
} from 'smily-admin-ui/utils/config';
import type { MenuConfigSourceEntry } from 'smily-admin-ui/utils/config/menu-config';
import { topLevelRoutes } from 'smily-admin-ui/utils/config/menu-config';

function generateMenuEntry(
  sourceEntry: MenuConfigSourceEntry,
  abilities: AbilitiesService,
  cache: CacheService,
  routeIcons: RouteIcons,
) {
  const { route } = sourceEntry;

  return {
    ...sourceEntry,
    ...routeIcons[route],
    isNew: cache.newFeatureSessionCounts.some(([featureName, sessionCount]) => {
      if (sessionCount > 3) {
        return false;
      }

      const featureRoute = featureDiscoveryConfig.find(
        ({ name }) => name === featureName,
      )!.route;

      if (!featureRoute) {
        return false;
      }

      return featureRoute.split('.').at(0)! === route.split('.').at(0)!;
    }),
    linkComponent: abilities.can(`access ${route} route`)
      ? undefined
      : SidebarLockedMenuItemComponent,
  } as MenuConfigEntry;
}

interface TemplatesApplicationSignature {
  Blocks: { default: [] };
  Args: {
    controller?: ApplicationController;
  };
}

class TemplatesApplication extends Component<TemplatesApplicationSignature> {
  @service abilities!: AbilitiesService;
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: StoreService;

  routeIcons = getOwner(this).resolveRegistration(
    'route-icons:main',
  ) as RouteIcons;

  get showLayout() {
    return (
      this.session.isAuthenticated &&
      this.abilities.can('access app in account')
    );
  }

  get filteredMenuConfig() {
    return menuConfig
      .filter(({ route }) => this.abilities.can(`show ${route} route`))
      .map((entry) => {
        if (
          entry.route !== 'task-management' ||
          this.abilities.can('show tasks templates route')
        ) {
          return entry;
        }

        return { ...entry, children: undefined };
      });
  }

  get menuConfig() {
    if (isMobile(this.media)) {
      return [];
    }

    return this.filteredMenuConfig.map((entry) =>
      generateMenuEntry(entry, this.abilities, this.cache, this.routeIcons),
    );
  }

  get mobileMenuConfig(): MobileMenuConfig | undefined {
    if (!isMobile(this.media)) {
      return undefined;
    }

    const mainNavigation = this.filteredMenuConfig
      .filter((entry) => !!entry.order)
      .map((entry) =>
        generateMenuEntry(entry, this.abilities, this.cache, this.routeIcons),
      );
    const sidebarNavigation = this.filteredMenuConfig
      .filter((entry) => !entry.order)
      .map((entry) =>
        generateMenuEntry(entry, this.abilities, this.cache, this.routeIcons),
      );

    return {
      mainNavigation,
      sidebarNavigation,
      topLevelRoutes,
    };
  }

  get hasEmbeddedApp(): boolean {
    const { currentRouteName } = this.router;

    return (
      currentRouteName.startsWith('apps.') && currentRouteName !== 'apps.index'
    );
  }

  get metricsAdapterConfig(): MetricsAdapterConfig {
    const { analytics } = config;

    return Object.entries(analytics)
      .map(([adapterName, trackingConfig]) => ({
        name: adapterName,
        environments: ['all'],
        config: {
          id: trackingConfig.id as string,
          ...trackingConfig.config,
        },
      }))
      .filter(({ config: { id } }) => id);
  }

  @action
  async login(): Promise<void> {
    return this.store
      .createRecord('accounts-user')
      .fetchSession() as unknown as Promise<void>;
  }

  <template>
    {{pageTitle (if this.session.account.isSmily 'Smily' 'BookingSync')}}

    {{#if this.showLayout}}
      <SmilyLayout
        @menuConfig={{this.menuConfig}}
        @mobileMenuConfig={{this.mobileMenuConfig}}
        @hasEmbeddedApp={{this.hasEmbeddedApp}}
        @sidebarHeaderComponent={{SidebarHeaderComponent}}
        @sidebarFooterComponent={{SidebarFooterComponent}}
        @mobileMenuFooterComponent={{SidebarMobileMenuFooter}}
      >
        {{outlet}}
      </SmilyLayout>
    {{else}}
      {{outlet}}
    {{/if}}

    <FeatureDiscovery />

    <Analytics @adapterConfig={{this.metricsAdapterConfig}} />

    {{#if
      (and (can 'access legal terms modal release') (can 'accept legal-terms'))
    }}
      <AcceptanceModals @returnPath={{@controller.returnPath}} />
    {{/if}}

    {{#if (can 'see suspension info in account')}}
      <AccountSuspensionBanner />
    {{/if}}
  </template>
}

export default RouteTemplate(TemplatesApplication);
